import React, { createContext, useReducer, useEffect } from 'react';

import AppReducer from './AppReducer';

const deliveryOptions = [
  {
    // name: 'Вземане лично от Офис на ЕКОНТ',
    name: 'ЕКОНТ Офис ',
    price: '7.40'
  },
  {
    // name: 'Вземане лично от Офис на СПИДИ',
    name: 'СПИДИ Офис ',
    price: '5.70'
  },
  {
    name: 'До Адрес',
    price: '8.70'
  }
];

// initial state
const initialState = {
  productList: sessionStorage.getItem('productList')
    ? JSON.parse(sessionStorage.getItem('productList'))
    : [],

  cartList: localStorage.getItem('cartList') ? JSON.parse(localStorage.getItem('cartList')) : [],

  searchList: sessionStorage.getItem('searchList')
    ? JSON.parse(sessionStorage.getItem('searchList'))
    : [],

  shippingOption: sessionStorage.getItem('shippingOption')
    ? JSON.parse(sessionStorage.getItem('shippingOption'))
    : deliveryOptions[0],

  deliveryOptions: sessionStorage.getItem('deliveryOptions')
    ? JSON.parse(sessionStorage.getItem('deliveryOptions'))
    : deliveryOptions,

  menu: sessionStorage.getItem('menu') ? JSON.parse(sessionStorage.getItem('menu')) : null,

  materialCategories: sessionStorage.getItem('materialCategories')
    ? JSON.parse(sessionStorage.getItem('materialCategories'))
    : null,

  categoryIdUrlParam: sessionStorage.getItem('categoryIdUrlParam')
    ? JSON.parse(sessionStorage.getItem('categoryIdUrlParam'))
    : null,

  subcategoryIdUrlParam: sessionStorage.getItem('subcategoryIdUrlParam')
    ? JSON.parse(sessionStorage.getItem('subcategoryIdUrlParam'))
    : null,

  materialIdUrlParam: sessionStorage.getItem('materialIdUrlParam')
    ? JSON.parse(sessionStorage.getItem('materialIdUrlParam'))
    : null,

  pageUrlParam: sessionStorage.getItem('pageUrlParam')
    ? JSON.parse(sessionStorage.getItem('pageUrlParam'))
    : 0, //we start to fetch products from page 1

  sizeUrlParam: sessionStorage.getItem('sizeUrlParam')
    ? JSON.parse(sessionStorage.getItem('sizeUrlParam'))
    : 4, // default number of products to fetch

  termParam: sessionStorage.getItem('termParam')
    ? JSON.parse(sessionStorage.getItem('termParam'))
    : null, // default search term value

  lang: sessionStorage.getItem('lang') ? JSON.parse(sessionStorage.getItem('lang')) : 'bg'
};

// create context
export const GlobalContext = createContext(initialState);

//provider component
export const GlobalProvider = (props) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  useEffect(() => {
    sessionStorage.setItem('productList', JSON.stringify(state.productList));
    localStorage.setItem('cartList', JSON.stringify(state.cartList));
    sessionStorage.setItem('searchList', JSON.stringify(state.searchList));
    sessionStorage.setItem('lang', JSON.stringify(state.lang));
    sessionStorage.setItem('shippingOption', JSON.stringify(state.shippingOption));
    sessionStorage.setItem('deliveryOptions', JSON.stringify(state.deliveryOptions));
    sessionStorage.setItem('categoryIdUrlParam', JSON.stringify(state.categoryIdUrlParam));
    sessionStorage.setItem('subcategoryIdUrlParam', JSON.stringify(state.subcategoryIdUrlParam));
    sessionStorage.setItem('materialIdUrlParam', JSON.stringify(state.materialIdUrlParam));
    sessionStorage.setItem('termParam', JSON.stringify(state.termParam));
    sessionStorage.setItem('menu', JSON.stringify(state.menu));
    sessionStorage.setItem('materialCategories', JSON.stringify(state.materialCategories));
  }, [state]);

  //actions
  const loadProductList = (productListData) => {
    dispatch({ type: 'LOAD_PRODUCT_LIST', payload: productListData });
  };

  const clearProductList = () => {
    dispatch({ type: 'CLEAR_PRODUCT_LIST' });
  };

  const addCartItem = (cartItm) => {
    dispatch({ type: 'ADD_CART_ITEM', payload: cartItm });
  };

  const clearCartList = () => {
    dispatch({ type: 'CLEAR_CART_LIST' });
  };

  const loadSearchList = (productSearchData) => {
    dispatch({ type: 'LOAD_PRODUCT_SEARCH_LIST', payload: productSearchData });
  };

  const clearSearchList = () => {
    dispatch({ type: 'CLEAR_PRODUCT_SEARCH_LIST' });
  };

  // we use changeCartItemQty instead and set the quantity to 0
  // const removeCartItem = (productId) => {
  //   dispatch({ type: 'REMOVE_CART_ITEM', payload: productId });
  // };

  const changeCartItemQty = (product) => {
    dispatch({ type: 'CHANGE_CART_ITEM_QTY', payload: product });
  };

  const selectShippingOption = (shippingOptionData) => {
    dispatch({ type: 'SELECT_DELIVERY_OPTION', payload: shippingOptionData });
  };

  const setCategoryIdUrlParam = (categoryIdUrl) => {
    dispatch({ type: 'SET_CATEGORY_ID_URL_PARAM', payload: categoryIdUrl });
  };

  const setSubCategoryIdUrlParam = (subcategoryIdUrl) => {
    dispatch({ type: 'SET_SUBCATEGORY_ID_URL_PARAM', payload: subcategoryIdUrl });
  };

  const setMaterialIdUrlParam = (materialIdUrlParam) => {
    dispatch({ type: 'SET_MATERIAL_ID_URL_PARAM', payload: materialIdUrlParam });
  };

  const setPageUrlParam = (pageUrlParam) => {
    dispatch({ type: 'SET_PAGE_URL_PARAM', payload: pageUrlParam });
  };

  const setSizeUrlParam = (sizeUrlParam) => {
    dispatch({ type: 'SET_SIZE_URL_PARAM', payload: sizeUrlParam });
  };

  const setTermParam = (termParam) => {
    dispatch({ type: 'SET_TERM_PARAM', payload: termParam });
  };

  const setMenu = (menu) => {
    dispatch({ type: 'SET_MENU', payload: menu });
  };

  const setMaterialCategories = (materials) => {
    dispatch({ type: 'SET_MATERIAL_CATEGORIES', payload: materials });
  };

  return (
    <GlobalContext.Provider
      value={{
        productList: state.productList,
        cartList: state.cartList,
        searchList: state.searchList,
        shippingOption: state.shippingOption,
        deliveryOptions: state.deliveryOptions,
        categoryIdUrlParam: state.categoryIdUrlParam,
        subcategoryIdUrlParam: state.subcategoryIdUrlParam,
        materialIdUrlParam: state.materialIdUrlParam,
        pageUrlParam: state.pageUrlParam,
        sizeUrlParam: state.sizeUrlParam,
        termParam: state.termParam,
        lang: state.lang,
        menu: state.menu,
        materialCategories: state.materialCategories,
        loadProductList,
        clearProductList,
        addCartItem,
        clearCartList,
        loadSearchList,
        clearSearchList,
        changeCartItemQty,
        selectShippingOption,
        setCategoryIdUrlParam,
        setSubCategoryIdUrlParam,
        setMaterialIdUrlParam,
        setPageUrlParam,
        setSizeUrlParam,
        setTermParam,
        setMenu,
        setMaterialCategories
      }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
